import React, { useEffect, useRef, useState } from 'react';
import Text from '../../assets/images/Text.png';
import Tree from '../../assets/images/Tree.svg';
import gsap from 'gsap';

const Splash = () => {
  const [showAnimation, setShowAnimation] = useState(true);
  const splashScreen = useRef();
  const imgBlock = useRef();
  const textBlock = useRef();

  useEffect(() => {
    if (!showAnimation) {
      return;
    }

    setShowAnimation(false);

    gsap.from(textBlock.current, { delay: 2, duration: 1, opacity: 0 });
    gsap.to(splashScreen.current, { delay: 3, backgroundColor: '#14142A' });
    gsap.from(imgBlock.current, {
      delay: 4,
      duration: 0.3,
      opacity: 0,
      scale: 0.5,
    });
    gsap.to(splashScreen.current, {
      delay: 5,
      duration: 0.8,
      opacity: 0,
      display: 'none',
    });
    return () => {
      setShowAnimation(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="splashScreen" ref={splashScreen}>
      <div className="contentSection">
        <div className="imgBlock">
          <img src={Tree} alt="Logo" ref={imgBlock} />
        </div>
        <div className="textBlock">
          <img
            className="mb-4"
            src={Text}
            alt="Business Name"
            ref={textBlock}
          />
          <p className="provide-state">
            {/* We provide a state-of-the art dental care for you and your family */}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Splash;
