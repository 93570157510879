import React from 'react';
import { Link } from 'react-router-dom';

const TeamMember = (props) => {
	let image = require('../../assets/images/' + props.image);
	return (
		<div className='team-member mt-1'>
			<img src={image} alt='' />
			<h4 className='general-head m-0'>{props.name}</h4>
			<p>{props.title}</p>
			<Link to={'/appointment'} className='btn-text'>
				Free Consultation{' '}
			</Link>
		</div>
	);
};
export default TeamMember;
