import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <NavLink activeclassname="active" to={"/"}>
          <img src={require("../assets/images/home.svg").default} alt="" />
        </NavLink>

        <NavLink activeclassname="active" to={"/appointment"}>
          <img src={require("../assets/images/calendar.svg").default} alt="" />
        </NavLink>

        <NavLink activeclassname="active" to={"/services"}>
          <img src={require("../assets/images/file.svg").default} alt="" />
        </NavLink>

        <NavLink activeclassname="active" to={"/clinic"}>
          <img src={require("../assets/images/location.svg").default} alt="" />
        </NavLink>
      </footer>
    </>
  );
};

export default Footer;
