import React from "react";

const ServiceDataComponent = props => {
  let image = require("../../assets/images/" + props.imageURL);
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="service-div">
          <div className="service-data">
            <h3>{props.serviceName}</h3>
            <p>{props.description}</p>
          </div>
          <div className="serice-img">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
      <div className="clearfix" />
    </>
  );
};

export default ServiceDataComponent;
