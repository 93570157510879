import React from "react";
import { Link } from "react-router-dom";
import Gif from "../../assets/images/animation_640_kyt1nngd.gif";

const Confirmation = () => {
  return (
    <>
      <div className="confirmation-text">
        <img src={Gif} alt="confirmation gif" />
        <h4>Appointment Confirmed!</h4>
        <p>You will also receive an email about your Schedule</p>
      </div>
      <Link to={"/"} className="btn-confirm">
        Back to Homepage
      </Link>
    </>
  );
};

export default Confirmation;
