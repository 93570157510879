import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Header = props => {
  const navigate = useNavigate();
  return (
    <header>
      {/* <PopupIos /> */}
      {props.pageName !== "Home Page" ? (
        <NavLink
          to={{}}
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={require("../assets/images/back.svg").default} alt="" />
        </NavLink>
      ) : (
        <></>
      )}
      <span>{props.pageName}</span>
    </header>
  );
};
export default Header;
