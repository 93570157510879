import React from 'react';

const Review = (props) => {
  return (
    <div className="review-style">
      <div className="user-rating">
        <p>
          <iframe
            src={'https://www.youtube.com/embed/' + props.link}
            title="titleone"
            frameBorder="0"
          />
        </p>
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default Review;
