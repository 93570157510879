import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import ServiceDataComponent from "./serviceDataComponent";
import { useEffect } from "react";

const Services = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });

  return (
    <>
      <Header pageName="Services" />
      <div className="all-services">
        <div className="row">
          <ServiceDataComponent
            serviceName="Cosmetic Dentistry"
            description="Improve functionality , increase overall dental health and appearance routinely."
            imageURL="sr-1.svg"
          />
          <ServiceDataComponent
            serviceName="General Dentistry"
            description="Maintain the overall integrity of your teeth for the long-term."
            imageURL="sr-2.svg"
          />
          <ServiceDataComponent
            serviceName="Restorative Dentistry"
            description="Include everything from simple filling to root canal, implants and the removal of fractured or decayed teeth"
            imageURL="sr-3.svg"
          />
          <ServiceDataComponent
            serviceName="Orthodontics"
            description="A unique segment of dentistry that is intended to align and correct teeth and jaws that are improperly positioned."
            imageURL="sr-4.svg"
          />
          <ServiceDataComponent
            serviceName="Surgical Dentistry"
            description="A unique aspect of dental treatment that focuses on problems associated with soft and hard tissue of the jaw, mouth and face."
            imageURL="sr-5.svg"
          />
          <ServiceDataComponent
            serviceName="Periodontics"
            description="A dental specialty focusing exclusively in the inflammatory disease."
            imageURL="sr-6.svg"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
