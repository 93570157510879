import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Date from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Confirmation from './Confirmation';
import moment from 'moment';

const services = [
  {
    name: 'Cosmetic denstistry',
    description:
      'Improve functionality, increase overall dental health and appearance routinely',
    image: 'sr-1.svg',
  },
  {
    name: 'General dentistry',
    description:
      'Maintain the overall integrity of your teeth for the long-term.',
    image: 'sr-2.svg',
  },
  {
    name: 'Restorvative Dentistry',
    description:
      'Include everything from simple filling to root canal, implants and the removal of fractured or decayed teeth',
    image: 'sr-3.svg',
  },
  {
    name: 'Orthodontics',
    description:
      'A unique segment of dentistry that is intended to align and correct teeth and jaws that are improperly positioned.',
    image: 'sr-4.svg',
  },
  {
    name: 'Surgical Dentistry',
    description:
      ' A unique aspect of dental treatment that focuses on problems associated with soft and hard tissue of the jaw, mouth and face.',
    image: 'sr-5.svg',
  },
  {
    name: 'Periodontics',
    description:
      'A dental specialty focusing exclusively in the inflammatory disease.',
    image: 'sr-6.svg',
  },
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-60%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const AppointmentScreen = () => {
  const url = `${process.env.REACT_APP_EMAIL_URL}/emails?type=leads&to=${process.env.REACT_APP_EMAIL_TO}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [patientAppointmentDate, setPatientAppointmentDate] = useState(
    moment(),
  );
  const [patientService, setPatientService] = useState(null);
  const [alert, setAlert] = useState(null);

  const [data, setData] = useState({
    patientPhone: '',
    patientMessage: '',
    patientAppointmentTime: '',
    patientEmail: '',
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      patientPhone: data.patientPhone,
      patientAppointmentTime: data.patientAppointmentTime,
      patientMessage: data.patientMessage,
      patientEmail: data.patientEmail,
      patientService: patientService,
      patientAppointmentDate: patientAppointmentDate.format('YYYY-MM-DD'),
    };

    axios
      .post(url, formData)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    setAlert('Confirmed');
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  let inputProps = {
    name: 'patientAppointmentDate',
    placeholder: 'Select Date',
    className: 'date-btn',
    autoComplete: 'off',
    onChange: (patientAppointmentDate) =>
      setPatientAppointmentDate(patientAppointmentDate),
  };

  return alert !== null ? (
    <Confirmation />
  ) : (
    <>
      <Header pageName="Appointment" />
      <section className="main-section">
        <form className="appoint-form" onSubmit={(e) => handleSubmit(e)}>
          <label> Date </label>
          <div>
            <Date
              inputProps={inputProps}
              timeFormat={false}
              closeOnSelect={true}
            />
          </div>
          <label> Time </label>
          <div className="select_time">
            <label
              className={
                data.patientAppointmentTime === 'Morning' ? 'active' : ''
              }
            >
              Morning
              <input
                type="radio"
                id="morning"
                value={'Morning'}
                name="patientAppointmentTime"
                onChange={(e) => handleChange(e)}
              />
            </label>
            <label
              className={
                data.patientAppointmentTime === 'Evening' ? 'active' : ''
              }
            >
              Afternoon
              <input
                type="radio"
                id="afternoon"
                value={'Evening'}
                onChange={(e) => handleChange(e)}
                name="patientAppointmentTime"
              />
            </label>
          </div>

          <label> Phone </label>
          <input
            type="text"
            name="patientPhone"
            value={data.patientPhone}
            onChange={(e) => handleChange(e)}
            className="date-btn"
            placeholder="Your Number"
            autoComplete="off"
          />

          <label> Email </label>
          <input
            type="email"
            name="patientEmail"
            value={data.patientEmail}
            onChange={(e) => handleChange(e)}
            className="date-btn"
            placeholder="Your Email"
            autoComplete="off"
            required
          />

          <label> Service </label>
          {/* Button trigger modal */}
          <button type="button" className="date-btn" onClick={openModal}>
            {patientService !== null ? patientService : 'Select Service'}
            <img
              src={require('../../assets/images/forward.svg').default}
              alt=""
            />
          </button>

          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <Header pageName="Select Service" />
                  <div className="all_services">
                    {services.map((service, index) => {
                      return (
                        <div
                          className="service-div"
                          onClick={() => setPatientService(service.name)}
                          key={index}
                        >
                          <div className="service-data">
                            <h3>{service.name}</h3>
                            <p>{service.description}</p>
                          </div>
                          <div className="serice-img">
                            <img
                              src={require(`../../assets/images/${service.image}`)}
                              alt=""
                            />
                          </div>
                          <div className="clearfix" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="confirm-btn">
                    <button onClick={closeModal}>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <label> Message </label>
          <textarea
            name="patientMessage"
            id="patientMessage"
            value={data.patientMessage}
            onChange={(e) => handleChange(e)}
            cols={30}
            rows={10}
            placeholder={'Share a reply'}
          />
          <span className="hint">
            Complete the form and we will send you a confirmation within 24
            hours.
          </span>
          <div className="confirm-btn-form">
            <button type="submit" data-dismiss="modal" aria-label="Close">
              Make an Appointment
            </button>
          </div>
        </form>
      </section>
      {!isOpen && <Footer />}
    </>
  );
};

export default AppointmentScreen;
