import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Splash from './components/home/splash';
import PopupIos from './components/popupIos';

ReactDOM.render(
  <React.StrictMode>
    <Splash />
    <PopupIos />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
