import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import TeamMember from '../home/TeamMember';
import Review from './Review';

const Clinic = () => {
  const [tabs, setTabs] = useState('about');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header pageName="Clinic" />
      <section className="main-section clinic-main">
        <img
          src={require('../../assets/images/clinic-1.jpeg')}
          alt=""
          className="clinic-ban"
        />
        <h1 className="dr-name">Walnut Creek Dental</h1>
        <div className="rating">
          <i className="fa fa-star" aria-hidden="true" />
          4.8 (456 reviews)
        </div>
        <h4 className="general-head">Information</h4>
        <div className="clinic-address">
          <h5>
            <img
              src={require('../../assets/images/cl-location.svg').default}
              alt=""
            />
            Location
          </h5>
          <p>2021 Mt Diablo Blvd#100 , Walnut Creek , CA 94596 , USA</p>
          <a href="https://goo.gl/maps/UqqSi3ci5KDPMreGA"> Get Directions </a>
          <h5 className="mt-4">
            <img src={require('../../assets/images/Time.svg').default} alt="" />
            Operation hours
          </h5>
          <p>Mon- Fri</p>
          <ul>
            <li>08:00 - 17:00</li>
          </ul>
          <p>Sat-Sun:</p>
          <ul>
            <li>Closed</li>
          </ul>
        </div>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <span
              className={
                tabs === 'about'
                  ? 'nav-item nav-link active'
                  : 'nav-item nav-link'
              }
              onClick={() => {
                setTabs('about');
                return false;
              }}
              style={{ cursor: 'pointer' }}
            >
              About
            </span>
            <span
              className={
                tabs === 'teams'
                  ? 'nav-item nav-link active'
                  : 'nav-item nav-link'
              }
              onClick={() => {
                setTabs('teams');
              }}
              style={{ cursor: 'pointer' }}
            >
              Team
            </span>
            <span
              className={
                tabs === 'testimonial'
                  ? 'nav-item nav-link active'
                  : 'nav-item nav-link'
              }
              onClick={() => {
                setTabs('testimonial');
              }}
              style={{ cursor: 'pointer' }}
            >
              Testimonials
            </span>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className={
              tabs === 'about' ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <p>
              At Walnut Creek Dental, our primary focus and key to success is
              our belief in patient-centered care. We believe this is dentistry
              at its best.
            </p>
            <p>
              When you come into Walnut Creek Dental, you will be instantly
              welcomed by our team with heart-warming compassion and support of
              your dental needs. By doing this, we are able to treat our
              patients as they want to be treated.
            </p>
            <p>
              Thank you for selecting us as the best family and cosmetic dentist
              serving Walnut Creek, Lafayette, Pleasant Hill, Alamo, Danville,
              and other surrounding areas.
            </p>

            <div className="review-style">
              <h5>Invisalign</h5>
              <div className="user-rating">
                <p>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/pyJB0WARE_g?si=zavGxCe9BBLaaM9k"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </p>
              </div>
              <div className="clearfix" />
            </div>
          </div>
          <div
            className={
              tabs === 'teams' ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <TeamMember
              name="Dr. Darvishzadeh"
              image="Darvishzadeh.jpg"
              title="Doctor of Dental Surgery"
            />

            <TeamMember
              name="Dr. Aita K."
              image="koopahi_9c3e00d5d8.webp"
              title="Dentist"
            />
            {/* <TeamMember
							name="Yuka S."
							image="m-5.png"
							title="Dental Hygenist"
						/> */}

            <TeamMember
              name="Sarah B."
              image="m-2.jpg"
              title="Office Coordinator"
            />

            {/* <TeamMember
							name="Jessica S."
							image="avatar-f.png"
							title="Front Office"
						/> */}
            <TeamMember
              name="Karima B."
              image="m-4.jpg"
              title="Dental Assistant"
            />

            <TeamMember
              name="Lynda O."
              image="m-3.jpg"
              title="Dental Assistant"
            />

            {/* <TeamMember
							name='Yoselin B.'
							image='avatar-f.png'
							title='Front Office'
						/> */}
          </div>

          <div
            className={
              tabs === 'testimonial'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <div className="">
              <Review name="Sarah" link="65uzQ3_DVf8" />
              <Review name="Eduard" link="2pBSBf-ZvEM" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Clinic;
