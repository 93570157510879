import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { isChrome } from 'react-device-detect';

const PopupIos = () => {
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const timeOut = useRef(null);

  const handleClose = (e) => {
    e.preventDefault();
    setShowInstallMessage(false);
  };

  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    if (isIos() && !isInStandaloneMode()) {
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        setShowInstallMessage(true);
      }, 6000);
    }
  }, []);

  return (
    <>
      {isChrome && (
        <Modal isOpen={showInstallMessage} className="popup-notify">
          <ModalBody>
            <span
              className="close-notify"
              style={{ cursor: 'pointer' }}
              onClick={(e) => handleClose(e)}
            >
              <img src={require('../assets/images/Close.png')} alt="" />
            </span>
            <img
              src={require('../assets/images/dock.png')}
              className="dock-notify"
              alt="dock"
            />
            <span className="tutorial-chrome" />
            <div>
              <p className="popup-text">Install App on your Phone</p>
              <p className="popup-text-1">
                {' '}
                please tap{' '}
                <img
                  src={require('../assets/images/share.png')}
                  alt="share icon"
                />
              </p>
              <h4 className="popup-text-2">
                and then <strong>Add to your home screen</strong>
                <img src={require('../assets/images/add.png')} alt="add icon" />
              </h4>
            </div>
          </ModalBody>
        </Modal>
      )}
      {!isChrome && (
        <Modal isOpen={showInstallMessage} className="popup-notify">
          <ModalBody>
            <span
              className="close-notify"
              style={{ cursor: 'pointer' }}
              onClick={(e) => handleClose(e)}
            >
              <img src={require('../assets/images/Close.png')} alt="" />
            </span>
            <img
              src={require('../assets/images/dock.png')}
              className="dock-notify"
              alt="dock"
            />
            <span className="tutorial-safari" />
            <div>
              <p className="popup-text">Install App on your Phone</p>
              <p className="popup-text-1">
                {' '}
                please tap{' '}
                <img
                  src={require('../assets/images/share.png')}
                  alt="share icon"
                />
              </p>
              <h4 className="popup-text-2">
                and then <strong>Add to your home screen</strong>
                <img src={require('../assets/images/add.png')} alt="add icon" />
              </h4>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default PopupIos;
