import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./components/home/index";
import AppointmentScreen from "./components/appointment/AppointmentScreen";
import ClinicScreen from "./components/clinic/ClinicScreen";
import Services from "./components/services/Services";
import Confirmation from "./components/appointment/Confirmation";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/appointment" element={<AppointmentScreen />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/clinic" element={<ClinicScreen />} />
          <Route exact path="/confirmed" element={<Confirmation />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
