import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const HomeScreen = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });

  const accordionData = {
    title: 'Walnut Creek Dental',
    content: `Our primary focus and key to success is our belief in patient centered
    core. We believe this is dentistry at its best.`,
  };

  const { title, content } = accordionData;
  const [isActive, setIsActive] = useState(false);
  let imageUrl = isActive ? 'minus.svg' : 'plus.svg';

  const accordionStyle = {
    borderColor: '#14142B',
    borderWidth: '2px',
  };
  const titleStyle = {
    fontfamily: 'poppins',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '16px',
    color: '#14142B',
  };

  return (
    <>
      <section className="main-section">
        <Header pageName="Home Page" />
        <div className="main-banner">
          <h2>Excellent Techniques</h2>
          <h6>For Healthy dental condition</h6>
          <Link to={'/appointment'}> Free Consultation </Link>
        </div>
        {/* <p className="provide-state">
          We provide a state-of-the art dental care for you and your family
        </p> */}
        {/* <h4 className="general-head mt-2">The Founder</h4> */}
        <div className="team-member mt-3">
          <img src={require('../../assets/images/Darvishzadeh.jpg')} alt="" />
          <h4 className="general-head m-0">Dr. Darvishzadeh</h4>
          <p>Doctor of Dental Surgery</p>
          <Link to={'/services'} className="btn-text">
            Our Services{' '}
          </Link>
        </div>

        <div className="operating-hours">
          <h4 className="general-head">
            <img
              src={require('../../assets/images/fi_clock.svg').default}
              className="mr-2"
              alt=""
            />
            Operation Hours
          </h4>
          <div className="times">
            <div className="left-con">Monday - Friday</div>
            <div className="right-con">08:00 - 17:00</div>
          </div>
          <div className="times">
            <div className="left-con">Saturday - Sunday</div>
            <div className="right-con">Closed</div>
          </div>
        </div>

        <h4 className="general-head">More Information about us</h4>
        {/* accordion start */}
        <div className="accordion">
          <div
            className="card"
            style={isActive === true ? accordionStyle : null}
          >
            <div className="card-header">
              <h2 className="mb-0">
                <div
                  className="btn btn-link btn-block text-left"
                  style={
                    isActive === true ? titleStyle : { textDecoration: 'none' }
                  }
                  onClick={() => setIsActive(!isActive)}
                >
                  {title}
                  <img
                    className={
                      isActive === true
                        ? 'accordion-sign'
                        : 'accordion-sign-inactive'
                    }
                    src={require(`../../assets/images/${imageUrl}`)}
                    alt=""
                  />
                </div>
              </h2>
            </div>
            <div className={isActive === true ? '' : 'collapse'}>
              <div
                className="card-body"
                style={{
                  color: '#4E4B66',
                  lineHeight: '24px',
                  letterSpacing: '0.75px',
                }}
              >
                {content}
              </div>
            </div>
          </div>
        </div>
        {/* accordion end */}
        <Footer />
      </section>
    </>
  );
};

export default HomeScreen;
